import cn from 'classnames';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FieldContainer from '../../../../components/field-container/FieldContainer';
import Select from '../../../../components/select';

const Products = ({
  className,
  products,
  onChange,
  value,
  disabled,
  required,
  innerRef,
  onKeyDown,
  readOnly,
  isTicketed,
}) => {
  const handleChange = val => {
    const targetProduct = find(products, p => p.productRef === val);
    onChange('item', targetProduct);
  };

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const handleDropDownVisibleChange = React.useCallback(isOpen => {
    setIsDropdownOpen(isOpen);
  }, []);

  const handleKeyDown = React.useCallback(
    e => {
      if (isDropdownOpen) return;

      onKeyDown?.(e);
    },
    [isDropdownOpen, onKeyDown]
  );

  return (
    <FieldContainer className={cn('products', className)} onKeyDownCapture={handleKeyDown}>
      {readOnly || isTicketed ? (
        <div className="product-text">{`${value?.id} / ${value?.name}`}</div>
      ) : (
        <Select
          value={value?.productRef || ''}
          onChange={handleChange}
          disabled={disabled}
          valuePath="productRef"
          displayPath={['id', 'name']}
          options={products}
          innerRef={innerRef}
          onDropdownVisibleChange={handleDropDownVisibleChange}
          selectOnTabPress
          interimValue={value}
          categoryPath="priceSource"
          defaultCategoryLabel="No Price"
        />
      )}
    </FieldContainer>
  );
};

export default styled(Products)`
  input {
    border: 0;
  }
  .product-text {
    margin-left: 10px;
  }
  &.validation-failed {
    .ant-select-selector {
      border-color: #b62121;
    }
  }
`;
