import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../../../../components/button/Button';
import Currency from '../../../../../Currency';
import { Translate } from '../../../../../localization/Translate';
import { useTranslateMessage } from '../../../../../localization/useTranslateMessage';
import { useOrderContext } from '../../../../../useOrderContext';
import { usePermissions } from '../../../../../usePermissions';
import { MODES } from '../../../../OrderForm';
import EditableRow from '../editable-row/EditableRow';
import style from './style';
import { useRef } from 'react';
import SelectField from '../../../../../../components/SelectField';
import { useOrderLinesContext } from '../../../order-lines-product-entry/useOrderLinesContext';
import DateTimePicker from '../../../../../../components/date-time-picker/DateTimePicker';

const StandardProductEntry = ({
  className,
  concreteLineItem,
  otherLineItems,
  handleUpdateConcreteLineItem,
  handleUpdateOtherLineItems,
  handleDeleteRow,
  onChange,
  linkedOrder,
  disabled,
  onAddRow,
  index = 0,
  readOnly,
  tickets,
  orderRef,
}) => {
  const {
    order: { rawOrder, isTicketed },
    masterData: { masterData },
    mode,
    form: { form },
    lists,
    saveOrderBusy,
    supplierParty: { timeZone },
  } = useOrderContext();

  const { removeOrderLine } = useOrderLinesContext();

  const availableConcreteProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(product => product?.type === 'CONCRETE'),
    [masterData.availableProducts]
  );
  const availableOtherProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(product => product?.type !== 'CONCRETE'),
    [masterData.availableProducts]
  );

  const ticketedProductRefs = React.useMemo(
    () =>
      tickets
        ?.filter(t => !t.ticket?.isVoided)
        ?.reduce?.((acc, ticket) => {
          const ticketedRefs = ticket?.ticket?.lineItems?.map?.(lineItem => lineItem.item.productRef);
          acc = [...acc].concat(ticketedRefs);
          return acc;
        }, []),
    [tickets]
  );

  const locationFieldRef = useRef();
  const concreteLineItemIsTicketed = React.useMemo(() => {
    const thisProductRef = concreteLineItem?.item?.productRef;

    return ticketedProductRefs?.includes?.(thisProductRef);
  }, [concreteLineItem?.item?.productRef, ticketedProductRefs]);

  const { has } = usePermissions();
  const showPricing = concreteLineItem?.priceSummary !== undefined && (has('showPricing') || has('editPricing'));
  const showFees = showPricing && !!linkedOrder?.priceSummary?.totalFees && !!+linkedOrder.priceSummary.totalFees;
  const showPricingTotals = mode.id === MODES.EDIT.id && showPricing;

  // TODO: I18N Editable Rows.

  const translateMessage = useTranslateMessage();

  return (
    <div className={cn(className, { disabled })}>
      {form?.linkedOrders?.length > 1 && (
        <div className="table-header">
          <div className="order-line-header">#{index + 1}</div>
          {!orderRef && index !== 0 && (
            <Button
              size="small"
              type="primary"
              onClick={() => removeOrderLine(index)}
              disabled={disabled}
              metricId={'core-order-detail-remove-order-line'}
            >
              <i className="icon fa fa-minus" />
              <Translate defaultMessage="Remove Order Line" stringId="removeOrderLine"/>
            </Button>
          )}
          <DateTimePicker
            value={linkedOrder?.startDateTime || form?.startDateTime}
            onChange={onChange}
            fieldLabel="Delivery Date"
            timeZone={timeZone}
          />
          <SelectField
            fieldLabel={translateMessage({ stringId: 'status', defaultMessage: 'Status' })}
            fieldName="supplierStatusRef"
            list={masterData?.supplierStatusCodes}
            required
            onChange={onChange}
            value={linkedOrder?.supplierStatusRef || form?.supplierStatusRef}
            valuePath="crn"
            displayPath="name"
            disabled={!form?.customerRef || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.supplierStatus }}
            selectOnTabPress
            readOnly={readOnly}
          />
          <SelectField
            fieldLabel={translateMessage({ stringId: 'scheduledLocation', defaultMessage: 'Scheduled Location' })}
            fieldName="locationRef"
            list={lists.locations.list}
            listBusy={lists.locations.busy}
            onChange={onChange}
            required
            value={linkedOrder?.locationRef || form?.locationRef}
            displayPath={['id', 'name']}
            innerRef={locationFieldRef}
            selectOnTabPress
            valuePath="crn"
            disabled={!form?.customerRef || saveOrderBusy}
            interimValue={{ fieldValue: rawOrder?.location }}
            readOnly={readOnly || isTicketed}
          />

          {/* {readOnly ? (
          <div style={{ height: '24px' }} />
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={onAddRow}
            disabled={disabled}
            metricId={'core-order-detail-add-product'}
          >
            <i className="icon fa fa-plus" />
            Add Product
          </Button>
        )} */}
        </div>
      )}
      <table className={cn('products-list standard', { disabled })}>
        <thead>
          <tr className="column-heading">
            <th className="product">
              <div className="label product-label">
                <Translate stringId="product" defaultMessage="Product" />*
              </div>
            </th>
            <th className="slump">
              <div className="label slump-label">
                <Translate stringId="slump" defaultMessage="Slump" />*
              </div>
            </th>
            <th className="quantity">
              <div className="label quantity-label">
                <Translate stringId="quantity" defaultMessage="Quantity" />*
              </div>
            </th>
            <th className="hold-quantity">
              <div className="label quantity-label">
                <Translate stringId="nextHoldQuantity" defaultMessage="Next Hold Quantity" />
              </div>
            </th>
            <th className="cleanup">
              <div className="label cleanup-label">
                <Translate stringId="cleanup" defaultMessage="Cleanup" />
              </div>
            </th>
            <th className="loadNumbers">
              <div className="label load-numbers-label">
                <Translate stringId="loadNumbers" defaultMessage="Load Number(s)" />
              </div>
            </th>

            {showPricing && (
              <th className="extended-price">
                <Translate stringId="extendedPrice" defaultMessage="Extended Price" />
              </th>
            )}
            {showPricing && (
              <th className="sales-tax">
                <Translate stringId="salesTax" defaultMessage="Sales Tax" />
              </th>
            )}
            {showFees && (
              <th className="total-fees">
                <Translate stringId="fees" defaultMessage="Fees" />
              </th>
            )}
            {showPricing && (
              <th className="total-price">
                <Translate stringId="totalPrice" defaultMessage="Total Price" />
              </th>
            )}

            <th className="actions-column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <EditableRow
            lineItem={concreteLineItem}
            availableProducts={availableConcreteProducts}
            onSave={handleUpdateConcreteLineItem}
            onDelete={handleDeleteRow}
            allowDelete={false}
            disabled={disabled}
            onAddRow={onAddRow}
            fieldName="concreteLineItem"
            readOnly={readOnly}
            isTicketed={concreteLineItemIsTicketed}
            taxPerProduct={linkedOrder?.priceSummary?.taxPerProduct}
            showFees={showFees}
          />
          {otherLineItems?.map?.((lineItem, idx) => {
            const ticketed = ticketedProductRefs?.includes?.(lineItem.productRef);
            return (
              <EditableRow
                lineItem={lineItem}
                availableProducts={availableOtherProducts}
                onSave={handleUpdateOtherLineItems}
                onDelete={handleDeleteRow}
                key={lineItem._id}
                allowDelete={true}
                disabled={disabled}
                onAddRow={onAddRow}
                readOnly={readOnly}
                ticketed={ticketed}
                taxPerProduct={linkedOrder?.priceSummary?.taxPerProduct}
                showFees={showFees}
              />
            );
          })}

          {showPricingTotals && (
            <tr className="total-row">
              <td colSpan="6" className="right-align total-label">
                <Translate stringId="totals" defaultMessage="Totals" />
              </td>
              <td className="right-align total-extended-price">
                <Currency value={linkedOrder?.priceSummary?.subtotalPrice} />
              </td>
              <td className="right-align total-sales-tax">
                {linkedOrder?.priceSummary && <Currency value={linkedOrder?.priceSummary?.salesTax} />}
              </td>
              {showFees && (
                <td className="right-align total-fees">
                  <Currency value={linkedOrder.priceSummary.totalFees} />
                </td>
              )}
              <td className="right-align total-total-price">
                {linkedOrder?.priceSummary && <Currency value={linkedOrder?.priceSummary?.totalPrice} />}
              </td>
              <td />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default styled(StandardProductEntry)`
  ${style}
`;
