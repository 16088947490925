import { Checkbox } from 'antd';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useForm } from '../../../../../../useForm';
import { Translate } from '../../../../../localization/Translate';
import { useOrderContext } from '../../../../../useOrderContext';
import Currency from '../../../../../Currency';
import { usePermissions } from '../../../../../usePermissions';
import ProductInputNumber from '../../../../../multiple-products/ProductInputNumber';
import Products from '../../../../../multiple-products/Products';
import ProductQuantity from '../../../../../ProductQuantity';
import style from './style';
import ProductInput from '../../../../../multiple-products/ProductInput';
import Button from '../../../../../../../../components/button/Button';
import { Drawer } from 'antd';
import SetupItemEditor from '../../../../../../../setup/setup-item-editor';
import { tabs } from '../../../../../../../setup/setup-mix/index';
import useSetup from '../../../../../../../setup/useSetup';
import { useMemo } from 'react';

const EditableRow = ({
  className,
  lineItem,
  availableProducts,
  disabled,
  onSave,
  onFocus,
  onDelete,
  allowDelete,
  onAddRow,
  fieldName,
  readOnly,
  isTicketed,
  taxPerProduct = true,
  showFees = false,
}) => {
  const {
    order: { order },
    masterData: { masterDataBusy },
  } = useOrderContext();
  const { form: productEntryForm, handleChange, omitPaths } = useForm({ ...lineItem }, fieldName);
  const { getSetupItem, setupItem } = useSetup('mix');
  const [minimumConcreteQuantity, setMinimumConcreteQuantity] = useState(0);

  useEffect(() => {
    handleChange('priceSummary', lineItem?.priceSummary);
  }, [handleChange, lineItem?.priceSummary]);

  useEffect(() => {
    handleChange('orderedQuantity', lineItem?.orderedQuantity);
  }, [handleChange, lineItem?.orderedQuantity]);

  useEffect(() => {
    handleChange('properties.cleanup', lineItem?.properties?.cleanup);
  }, [handleChange, lineItem?.properties?.cleanup]);

  useEffect(() => {
    setMinimumConcreteQuantity(order?.ticketsMeta?.totalDeliveredQuantity || 0);
  }, [order]);

  useDeepCompareEffect(() => {
    onSave(productEntryForm);
  }, [
    productEntryForm,
    productEntryForm?.properties?.slump,
    productEntryForm?.properties?.holdQuantity,
    productEntryForm?.properties?.cleanup,
    productEntryForm?.properties?.loadNumbers,
  ]);

  const handleProductChange = React.useCallback(
    (field, value) => {
      handleChange(field, value);
      handleChange('priceSummary', undefined);
      if (value?.item?.type !== 'CONCRETE') {
        omitPaths(['properties.slump', 'properties.cleanup']);
      }
      if (value?.item?.type === 'CONCRETE') {
        omitPaths(['properties.loadNumbers']);
      }

      if (value?.minimumLoadCharge) {
        handleChange('orderedQuantity', { value: 1, uomCode: value?.uomCode });
      } else {
        handleChange('orderedQuantity.uomCode', value?.uomCode);
      }

      handleChange('properties.slump', value?.limits?.consistence?.nominalValue);
    },
    [handleChange, omitPaths]
  );

  const handleFocus = React.useCallback(
    e => {
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleKeyDown = React.useCallback(
    e => {
      if (e.key === '+') {
        onAddRow();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onAddRow]
  );

  const handleDelete = React.useCallback(
    id => {
      if (disabled) return;
      onDelete(id);
    },
    [disabled, onDelete]
  );

  const [openDrawer, setDrawerOpen] = useState(false);

  const editorParams = {
    typeId: 'mix',
    editorType: 'tabs',
    tabs: tabs,
  };

  const handleUpdateProduct = useCallback(async () => {
    if (
      productEntryForm &&
      productEntryForm.item?.mix?.mixRef &&
      setupItem?.crn !== productEntryForm.item?.mix?.mixRef
    ) {
      await getSetupItem(productEntryForm.item.mix.mixRef);
    }
  }, [productEntryForm, getSetupItem, setupItem]);

  useEffect(() => {
    handleUpdateProduct();
  }, [handleUpdateProduct, productEntryForm?.item?.mix]);

  const { has } = usePermissions();
  const showPricing = productEntryForm?.priceSummary !== undefined && (has('showPricing') || has('editPricing'));

  const hasOverrideZero =
    (
      order?.schedule
        ?.map(s => s.overrides?.associatedProducts?.[lineItem.item?.productRef ?? ''])
        .filter(q => q === 0) ?? []
    ).length > 0;

  const invalidProduct = useMemo(() => {
    if (!availableProducts || !productEntryForm?.item || masterDataBusy) return null;
    return !availableProducts?.find(p => p?.productRef === productEntryForm?.item?.productRef);
  }, [availableProducts, productEntryForm?.item, masterDataBusy]);

  // TODO: I18N Need to trigger line 178

  return (
    <tr className={cn('editable-row', className, { disabled })} onFocus={handleFocus}>
      <td style={{ width: '100%*' }}>
        <div style={{ display: 'flex', width: '100%' }}>
          {invalidProduct && <i className="fas fa-times-circle invalid-product-icon"></i>}
          <div style={{ flex: 1, overflow: 'hidden' }}>
            <Products
              required
              value={productEntryForm?.item}
              onChange={handleProductChange}
              products={availableProducts}
              productsBusy={false}
              disabled={disabled}
              className={cn({ 'validation-failed': false })}
              onKeyDown={handleKeyDown}
              readOnly={readOnly || isTicketed}
            />
          </div>
          {productEntryForm?.item?.mix && (
            <div>
              <Button size="small" type="primary" onClick={e => setDrawerOpen(!openDrawer)}>
                <Translate stringId="details" defaultMessage="Details" />
              </Button>
              <Drawer
                width={600}
                title={`Mix Design Viewer - ${productEntryForm?.item?.name}`}
                placement="right"
                visible={openDrawer}
                onClose={() => setDrawerOpen(!openDrawer)}
                closable
                destroyOnClose
              >
                <SetupItemEditor item={setupItem} params={editorParams} removeButtons={true} disableButtons={true} />
              </Drawer>
            </div>
          )}
        </div>
      </td>
      <td>
        {productEntryForm?.item?.type === 'CONCRETE' && (
          <ProductInputNumber
            value={productEntryForm?.properties?.slump || ''}
            fieldName="properties.slump"
            onChange={handleChange}
            disabled={disabled}
            className={cn({ 'validation-failed': false })}
            onKeyDown={handleKeyDown}
            readOnly={readOnly}
            autoRefreshValue={true}
          />
        )}
      </td>
      <td>
        <ProductQuantity
          value={productEntryForm?.orderedQuantity}
          onChange={handleChange}
          uom={productEntryForm?.item?.uomCode}
          onKeyDown={handleKeyDown}
          disabled={disabled || lineItem?.item?.minimumLoadCharge}
          readOnly={readOnly}
          minValue={productEntryForm?.item?.type === 'CONCRETE' ? minimumConcreteQuantity?.value : 0}
          autoRefreshValue={true}
        />
      </td>
      <td>
        {productEntryForm?.item?.type === 'CONCRETE' && (
          <ProductInputNumber
            value={productEntryForm?.properties?.holdQuantity || ''}
            fieldName="properties.holdQuantity"
            onChange={handleChange}
            disabled={disabled}
            className={cn({ 'validation-failed': false })}
            onKeyDown={handleKeyDown}
            readOnly={readOnly}
          />
        )}
      </td>
      <td className="checkbox">
        {productEntryForm?.item?.type === 'CONCRETE' && (
          <Checkbox
            onChange={e => {
              handleChange('properties.cleanup', e?.target?.checked);
            }}
            checked={productEntryForm?.properties?.cleanup || false}
            onKeyDown={handleKeyDown}
            disabled={disabled || readOnly}
          />
        )}
      </td>

      <td>
        {productEntryForm?.item?.type !== 'CONCRETE' && (
          <ProductInput
            fieldName={'properties.loadNumbers'}
            onChange={handleChange}
            value={productEntryForm?.properties?.loadNumbers || ''}
            onKeyDown={handleKeyDown}
            disabled={
              hasOverrideZero ||
              productEntryForm?.loadTimeTrigger ||
              disabled ||
              readOnly ||
              lineItem?.item?.minimumLoadCharge
            }
            tooltip={
              hasOverrideZero ? 'This product has quantity overrides. See schedule for more details.' : undefined
            }
          />
        )}
      </td>

      {showPricing && (
        <td className="right-align">
          <Currency value={productEntryForm?.priceSummary?.extendedPrice} />
        </td>
      )}

      {showPricing && (
        <td className="right-align">
          {taxPerProduct && <Currency value={productEntryForm?.priceSummary?.salesTax} />}
        </td>
      )}

      {showPricing && (
        <td className="right-align" title={`From ${productEntryForm?.priceSummary?.priceSource} Price Book`}>
          {taxPerProduct && <Currency value={productEntryForm?.priceSummary?.totalPrice} />}
        </td>
      )}

      {showFees && <td></td>}

      <td>
        <div className="row-actions">
          {productEntryForm._id && allowDelete && !readOnly && !isTicketed && (
            <div className="delete" onClick={() => handleDelete(productEntryForm?._id)}>
              <i className="icon fa fa-trash" />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default styled(EditableRow)`
  ${style}
`;
