import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../components/button/Button';
import { Translate } from '../../localization/Translate';
import { useOrderContext } from '../../useOrderContext';
import { MODES } from '../OrderForm';
import { orderRequestStatuses } from '../../../../dispatch-order-request/utils/orderRequestStatuses';
import { useAppContext } from '../../../../../AppContext';

const Footer = ({ className }) => {
  const {
    form: { isValid },
    handleSaveClick,
    handleCancelClick,
    saveOrderBusy,
    mode,
    enableHighlightedFields,
    order: { readOnly },
    orderRequestStatus,
    masterData: { masterDataBusy },
    invalidProductsMessage,
    userCanEditOrders,
  } = useOrderContext();

  const { hideButtons } = useAppContext();

  const handleMouseOver = React.useCallback(() => {
    enableHighlightedFields();
  }, [enableHighlightedFields]);

  const showLabel = () => {
    if (mode.id === MODES.CREATE.id || mode.id === MODES.COPY.id) {
      return <Translate stringId="cancel" defaultMessage="Cancel" />;
    } else if (mode.id === MODES.REVIEW.id) {
      return <Translate stringId="rejectOrderRequest" defaultMessage="Reject Order Request" />;
    }
    return <Translate stringId="done" defaultMessage="Done" />;
  };

  return (
    <div>
      <div className={cn('footer', className)}>
        {hideButtons !== 'true' && (
          <Button
            onClick={handleCancelClick}
            metricId="core-order-detail-cancel-done"
            disabled={orderRequestStatus === orderRequestStatuses.rejected || saveOrderBusy}
          >
            {showLabel()}
          </Button>
        )}
        {readOnly ? (
          <div />
        ) : (
          <Button
            onClick={handleSaveClick}
            metricId="core-order-detail-save"
            loading={saveOrderBusy}
            disabled={
              !isValid ||
              orderRequestStatus === orderRequestStatuses.accepted ||
              orderRequestStatus === orderRequestStatuses.rejected ||
              invalidProductsMessage ||
              masterDataBusy ||
              !userCanEditOrders
            }
            onMouseOver={handleMouseOver}
          >
            {mode.id === MODES.REVIEW.id ? (
              <Translate stringId="acceptOrderRequest" defaultMessage="Accept Order Request" />
            ) : (
              <Translate stringId="save" defaultMessage="Save" />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default styled(Footer)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-area: bottom;
  position: sticky;
  bottom: 0;
  background-color: #000;
  padding-top: 10px;

  button {
    min-width: 100px;
  }
`;
