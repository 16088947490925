import { Checkbox } from 'antd';
import cn from 'classnames';
import { omit } from 'lodash';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import Button from '../../../../../../../../../components/button/Button';
import { useForm } from '../../../../../../../useForm';
import { useHash } from '../../../../../../../view-or-edit-order/edit-order/useHash';
import FlexColumn from '../../../../../../FlexColumn';
import IfConcrete from '../../../../../../IfConcrete';
import IfElse from '../../../../../../IfElse';
import ProductInputNumber from '../../../../../../multiple-products/ProductInputNumber';
import Products from '../../../../../../multiple-products/Products';
import ProductQuantity from '../../../../../../ProductQuantity';
import style from './style';

const EditLineItem = ({ className, availableProducts, availableOtherProducts, lineItem, onAccept, onDone, onDelete, readOnly, tickets }) => {
  const isCreating = !lineItem?._id;

  const initialValueRef = useRef(lineItem || {});

  const productRef = useRef();
  const { form, handleChange } = useForm(lineItem || {});

  const handleProductChange = React.useCallback(
    (field, value) => {
      handleChange(field, value);
    },
    [handleChange]
  );

  const { initialize, hasChanged } = useHash();
  const isDirty = useMemo(() => {
    return hasChanged(omit(form, 'item.locations'));
  }, [form, hasChanged]);

  const isValid = useMemo(() => {
    const alwaysRequired = form?.item?.productRef && form?.orderedQuantity?.value;

    return !!alwaysRequired;
  }, [form]);

  const handleDeleteClick = React.useCallback(() => {
    onDelete(form?._id);
    onDone();
  }, [form?._id, onDelete, onDone]);

  React.useEffect(() => {
    if (initialValueRef?.current) {
      initialize(initialValueRef.current);
    }
  }, [initialize]);

  const handleAccept = React.useCallback(() => {
    onAccept(omit(form, 'item.locations'));
    onDone();
  }, [form, onAccept, onDone]);

  const ticketedProductRefs = React.useMemo(
    () =>
      tickets
        ?.filter(t => !t.ticket?.isVoided)
        ?.reduce?.((acc, ticket) => {
          const ticketedRefs = ticket?.ticket?.lineItems?.map?.(lineItem => lineItem.item.productRef);
          acc = [...acc].concat(ticketedRefs);
          return acc;
        }, []),
    [tickets]
  );

  const isTicketed = React.useMemo(() => {
    const thisProductRef = form?.item?.productRef;

    return ticketedProductRefs?.includes?.(thisProductRef);
  }, [form?.item?.productRef, ticketedProductRefs]);

  return (
    <FlexColumn className={cn('edit-line-item', className)}>
      <div className="product">
        <div className="label">Product</div>
        <Products
          required
          value={form?.item}
          onChange={handleProductChange}
          products={form?.isPrimary ? availableProducts : availableOtherProducts}
          productsBusy={false}
          innerRef={productRef}
          className={cn({ 'validation-failed': false })}
          readOnly={readOnly}
          isTicketed={isTicketed}
          // onCancel={handleCancel}
          // onKeyDown={handleKeyDown}
        />
      </div>

      <div className="quantity-cleanup">
        <div className="quantity">
          <div className="label">Quantity</div>
          <ProductQuantity value={form?.orderedQuantity} onChange={handleChange} uom={form?.item?.uomCode} />
        </div>
        <IfConcrete product={form?.item}>
          <div className="cleanup">
            <div className="label">Cleanup</div>
            <div className="wrapper">
              <Checkbox
                onChange={e => {
                  handleChange('properties.cleanup', e?.target?.checked);
                }}
                checked={form?.properties?.cleanup || false}
              />
            </div>
          </div>
        </IfConcrete>
      </div>

      <div className="hold-quantity">
        <div className="label">Hold Quantity</div>
        <ProductInputNumber
          value={form?.properties?.holdQuantity || ''}
          fieldName="properties.holdQuantity"
          onChange={handleChange}
          className={cn({ 'validation-failed': false })}
        />
      </div>

      <FlexColumn className="actions">
        <div>
          <IfElse ifTrue={isDirty}>
            <Button className="discard" onClick={onDone}>
              Discard Changes
            </Button>
            <Button className="discard" onClick={onDone}>
              Go Back
            </Button>
          </IfElse>
          <Button className="accept" disabled={!isDirty || !isValid} onClick={handleAccept}>
            <IfElse ifTrue={isCreating}>
              <span>Add Product</span>
              <span>Accept Changes</span>
            </IfElse>
          </Button>
        </div>
        {!isCreating && (
          <div className="bottom">
            <Button
              className="delete"
              danger
              type="primary"
              onClick={handleDeleteClick}
              disabled={lineItem?.item?.type === 'CONCRETE'}
            >
              Delete Product
            </Button>
          </div>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

export default styled(EditLineItem)`
  ${style}
`;
