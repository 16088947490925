import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Spinner from '../../../../components/spinner/Spinner';
import FlexColumn from '../FlexColumn';
import { useOrderContext } from '../useOrderContext';
import Footer from './general/Footer';
import General from './general/General';
import style from './style';

const SimpleOrderForm = ({ className }) => {
  const { lists, mode } = useOrderContext();

  return (
    <FlexColumn className={cn(`SimpleOrderForm_${mode.id}`, className)} style={{ padding: '10px' }}>
      <Spinner spin={!lists?.customers.list || lists?.customers.busy}>
        <General />
        <Footer />
      </Spinner>
    </FlexColumn>
  );
};

export default styled(SimpleOrderForm)`
  ${style}
`;
