import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../../../components/spinner/Spinner';
import Tabs from '../../../../components/tabs';
import { tabClick } from '../../../../util/analytics';
import FlexColumn from '../FlexColumn';
import { Translate } from '../localization/Translate';
import { useOrderContext } from '../useOrderContext';
import { usePermissions } from '../usePermissions';
import Audit from './audit/Audit';
import Footer from './general/Footer';
import General from './general/General';
import Notes from './notes/Notes';
import Pricing from './pricing/Pricing';
import Schedule from './schedule/Schedule';
import Attachments from './attachments';
import style from './style';

export const MODES = {
  CREATE: { id: 'create-order', label: 'Create Order' },
  COPY: { id: 'copy-order', label: 'Copy Order' },
  EDIT: { id: 'edit-order', label: `Edit Order` },
  VIEW: { id: 'view-order', label: `View Order` },
  REVIEW: { id: 'review-order-request', label: 'Review Order Request' },
};

const OrderForm = ({ className }) => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    key => {
      tabClick(`core-order-detail-${key}`);
      navigate(`../${key}`);
    },
    [navigate]
  );

  const {
    form: { form, handleChange },
    lists,
    mode,
    saveOrderBusy,
  } = useOrderContext();

  const { has } = usePermissions();
  const showPricingTab = has('showPricing') || has('editPricing');

  const tabsDisabled = useMemo(
    () => mode.id === MODES.CREATE.id || mode.id === MODES.COPY.id || saveOrderBusy,
    [mode.id, saveOrderBusy]
  );

  const noteCount = useMemo(() => {
    const auditNotesExcluded = form?.notes?.filter?.(n => n.noteType !== 'audit') || [];

    const len = auditNotesExcluded.length;

    return len ? ` (${len})` : '';
  }, [form?.notes]);

  const attachmentCount = useMemo(() => {
    return (
      (form?.attachments?.orderAttachments?.items?.length ?? 0) +
      (form?.attachments?.projectAttachments?.items?.length ?? 0)
    );
  }, [form?.attachments]);

  return (
    <FlexColumn className={cn(`OrderForm_${mode.id}`, className)} style={{ padding: '10px' }}>
      <Spinner spin={!lists?.customers.list || lists?.customers.busy || !form?.supplier}>
        <Tabs type="card" activeKey={tab} onChange={handleTabChange}>
          <Tabs.TabPane
            tab={<Translate stringId="general" defaultMessage="General" />}
            key="general"
            disabled={tabsDisabled}
          >
            <General />
            <Footer />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Translate stringId="schedule" defaultMessage="Schedule" />}
            key="schedule"
            disabled={tabsDisabled}
          >
            <Schedule handleChange={handleChange} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<Translate stringId="audit" defaultMessage="audit" />} key="audit" disabled={tabsDisabled}>
            <Audit />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Translate stringId="notes" defaultMessage="Notes{noteCount}" values={{ noteCount }} />}
            key="notes"
            disabled={tabsDisabled}
          >
            <Notes />
          </Tabs.TabPane>
          {showPricingTab && (
            <Tabs.TabPane
              tab={<Translate stringId="pricing" defaultMessage="Pricing" />}
              key="pricing"
              disabled={tabsDisabled}
            >
              <Pricing />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={
              <Translate
                stringId="attachmentCount"
                defaultMessage="Attachments {attachmentCount, plural, =0 {} other {(#)}}"
                values={{ attachmentCount }}
              />
            }
            key="attachments"
            disabled={tabsDisabled}
          >
            <Attachments />
          </Tabs.TabPane>
        </Tabs>
      </Spinner>
    </FlexColumn>
  );
};

export default styled(OrderForm)`
  ${style}
`;
