import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../../../../../../components/button/Button';
import { PRIMARY_PRODUCT_TYPES } from '../../../../../../constants';
import Currency from '../../../../../Currency';
import Has from '../../../../../Has';
import { Translate } from '../../../../../localization/Translate';
import { useOrderContext } from '../../../../../useOrderContext';
import { MODES } from '../../../../OrderForm';
import EditableRow from '../editable-row/EditableRow';
import style from './style';

const excludePrimaryProducts = product => !PRIMARY_PRODUCT_TYPES.includes(product?.type);
const restrictToOrderType = orderType => product => product?.type === orderType;

const StandardAggregateProductEntry = ({
  className,
  aggregateLineItem,
  otherLineItems,
  handleUpdateAggregateLineItem,
  handleUpdateOtherLineItems,
  handleDeleteRow,
  disabled,
  onAddRow,
  readOnly,
  tickets,
}) => {
  const {
    order: { order },
    masterData: { masterData },
    mode,
    orderType,
  } = useOrderContext();

  const availableAggregateProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(restrictToOrderType(orderType)),
    [masterData?.availableProducts, orderType]
  );
  const availableOtherProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(excludePrimaryProducts),
    [masterData?.availableProducts]
  );

  const ticketedProductRefs = React.useMemo(
    () =>
      tickets
        ?.filter(t => !t.ticket?.isVoided)
        ?.reduce?.((acc, ticket) => {
          const ticketedRefs = ticket?.ticket?.lineItems?.map?.(lineItem => lineItem.item.productRef);
          acc = [...acc].concat(ticketedRefs);
          return acc;
        }, []),
    [tickets]
  );

  const aggregateLineItemIsTicketed = React.useMemo(() => {
    const thisProductRef = aggregateLineItem?.item?.productRef;

    return ticketedProductRefs?.includes?.(thisProductRef);
  }, [aggregateLineItem?.item?.productRef, ticketedProductRefs]);

  const showFees = !!order?.priceSummary?.totalFees && !!+order.priceSummary.totalFees;

  return (
    <div className={cn(className, { disabled })}>
      <div className="table-header">
        {readOnly ? (
          <div style={{ height: '24px' }} />
        ) : (
          <Button
            size="small"
            type="primary"
            onClick={onAddRow}
            disabled={disabled}
            metricId={'core-order-detail-add-product'}
          >
            <i className="icon fa fa-plus" />
            Add Product
          </Button>
        )}
      </div>
      <table className={cn('products-list standard', { disabled })}>
        <thead>
          <tr className="column-heading">
            <th className="product">
              <div className="label product-label">
                <Translate stringId="product" defaultMessage="Product" />
              </div>
            </th>

            <th className="quantity">
              <div className="label quantity-label">
                <Translate stringId="quantity" defaultMessage="Quantity" />
              </div>
            </th>
            <th className="hold-quantity">
              <div className="label quantity-label">
                <Translate stringId="nextHoldQuantity" defaultMessage="Next Hold Quantity" />
              </div>
            </th>

            <Has permission="showPricing">
              <th className="extended-price">
                <Translate stringId="extendedPrice" defaultMessage="Extended Price" />
              </th>
              <th className="sales-tax">
                <Translate stringId="salesTax" defaultMessage="Sales Tax" />
              </th>
              {showFees && (
                <th className="total-fees">
                  <Translate stringId="fees" defaultMessage="Fees" />
                </th>
              )}
              <th className="total-price">
                <Translate stringId="totalPrice" defaultMessage="Total Price" />
              </th>
            </Has>
            <Has not permission="showPricing">
              <th />
              <th />
              <th />
            </Has>
            <th className="actions-column">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <EditableRow
            lineItem={aggregateLineItem}
            availableProducts={availableAggregateProducts}
            onSave={handleUpdateAggregateLineItem}
            onDelete={handleDeleteRow}
            allowDelete={false}
            disabled={disabled}
            onAddRow={onAddRow}
            fieldName="primaryLineItem"
            readOnly={readOnly}
            isTicketed={aggregateLineItemIsTicketed}
            showFees={showFees}
          />
          {otherLineItems?.map?.((lineItem, idx) => {
            const ticketed = ticketedProductRefs?.includes?.(lineItem.productRef);
            return (
              <EditableRow
                lineItem={lineItem}
                availableProducts={availableOtherProducts}
                onSave={handleUpdateOtherLineItems}
                onDelete={handleDeleteRow}
                key={lineItem._id}
                allowDelete={true}
                disabled={disabled}
                onAddRow={onAddRow}
                readOnly={readOnly}
                ticketed={ticketed}
                showFees={showFees}
              />
            );
          })}

          <Has permission="showPricing">
            {mode.id === MODES.EDIT.id && (
              <tr className="total-row">
                <td colSpan="3" className="right-align total-label">
                  TOTALS
                </td>
                <td className="right-align total-extended-price">
                  <Currency value={order?.priceSummary?.subtotalPrice} />
                </td>
                <td className="right-align total-sales-tax">
                  {order?.priceSummary && <Currency value={order?.priceSummary?.salesTax} />}
                </td>
                {showFees && (
                  <td className="right-align total-fees">
                    <Currency value={order.priceSummary.totalFees} />
                  </td>
                )}
                <td className="right-align total-total-price">
                  {order?.priceSummary && <Currency value={order?.priceSummary?.totalPrice} />}
                </td>
                <td />
              </tr>
            )}
          </Has>
        </tbody>
      </table>
    </div>
  );
};

export default styled(StandardAggregateProductEntry)`
  ${style}
`;
