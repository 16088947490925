import style from '../style';
import React from 'react';
import styled from 'styled-components';
import InputText from '../../../components/input-text';
import InputSelect from '../../../components/input-select';
import { status } from '../options';
import SelectSetupItem from '../../../components/select-setup-item';
import SelectCarrier from '../../../components/select-carrier';
import SelectLocation from '../../../components/select-location';

const View = styled.div`
  ${style}
`;

const General = ({ vehicle, onChange }) => {
  return (
    <View>
      <InputText id="id" label="ID" value={vehicle?.id} onChange={onChange} required />
      <InputText id="name" label="Name" value={vehicle?.name} onChange={onChange} required />
      <SelectCarrier item={vehicle} onChange={onChange} required />
      <SelectSetupItem label="Vehicle Type" typeId="vehicle-type" item={vehicle} onChange={onChange} />
      <InputSelect id="status" label="Status" value={vehicle?.status} onChange={onChange} options={status} required />
      <SelectLocation label="Home Location" typeId="location" id={'homeLocation'} item={vehicle} onChange={onChange} />
      <SelectLocation
        label="From Location"
        typeId="location"
        id={'fromLocation'}
        item={vehicle}
        readOnly
        onChange={onChange}
      />
      <SelectLocation
        label="To Location"
        typeId="location"
        id={'toLocation'}
        item={vehicle}
        readOnly
        onChange={onChange}
      />
    </View>
  );
};

export default General;
