/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { get, find } from 'lodash';
import { Select as AntSelect } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import useIntlProps from '../../components/i18n/useIntlProps';
import getSelectedItemValue from './get-selected-item-value';

const Select = ({
  id,
  testid,
  name,
  mode,
  valuePath = 'id',
  displayPath = 'description',
  options = [],
  disabled,
  className,
  defaultValue,
  onChange,
  placeholder,
  showArrow,
  style,
  value,
  allowClear,
  error,
  touched,
  onFocus,
  innerRef,
  onBlur,
  loading,
  loadingPlaceholder = 'selectLoadingPlaceholder',
  onInputKeyDown,
  selectOnTabPress,
  onDropdownVisibleChange,
  dropdownRender,
  open,
  tabIndex,
  tagRender,
  displaySpacing = ' / ',
  categoryPath,
  defaultCategoryLabel = 'No Category',
  interimValue,
}) => {
  const { Option, OptGroup } = AntSelect;

  AntSelect.displayName = 'AntSelect';

  const [translatedPlaceholder, translatedLoadingPlaceholder] = useIntlProps(placeholder, loadingPlaceholder);

  const handleKeyPress = e => {
    if (selectOnTabPress && (e.code === 'Tab' || e.keyCode === 9) && typeof onChange === 'function') {
      const selectedValue = getSelectedItemValue(options, valuePath);
      if (selectedValue) {
        onChange(selectedValue);
      }
    }

    if (typeof onInputKeyDown === 'function') {
      onInputKeyDown();
    }
  };

  const preparedList = useMemo(() => {
    const isInList = interimValue ? find(options, { [valuePath]: interimValue[valuePath] }) : true;

    const listWithInterimValue = isInList ? options : [...(options || []), { ...interimValue }];
    return listWithInterimValue;
  }, [interimValue, options, valuePath]);

  const optionsGroupedByCategory = useMemo(() => {
    if (!categoryPath) return null;

    const temp = [];

    preparedList.forEach(option => {
      const category = temp.find(category => category.label === (option[categoryPath] || defaultCategoryLabel));
      if (!category) {
        temp.push({ label: option[categoryPath] || defaultCategoryLabel, options: [option] });
      } else {
        category.options.push(option);
      }
    });

    return temp;
  }, [categoryPath, defaultCategoryLabel, preparedList]);

  const generateOptionList = useCallback(
    option => {
      const label = []
        .concat(displayPath)
        .map(path => get(option, path))
        .filter(value => value)
        .join(displaySpacing);

      return (
        <Option
          key={`${option[valuePath]}__${option[[].concat(displayPath).join('')]}`}
          className="cx-option"
          value={get(option, valuePath)}
        >
          {option.icon && (
            <span className="option-icon">
              <i className={option.icon} />
            </span>
          )}{' '}
          {label}
        </Option>
      );
    },
    [displayPath, displaySpacing, valuePath]
  );

  return loading ? (
    <div className={cn(className, 'cx-loading-field')}>
      <div className="loading-spinner">
        <LoadingOutlined />
      </div>
      <div className="loading-placeholder">{translatedLoadingPlaceholder}</div>
    </div>
  ) : (
    <>
      <AntSelect
        id={id}
        name={name}
        mode={mode}
        data-testid={testid}
        disabled={disabled}
        showSearch
        filterOption={(input, option) => {
          if (option.children) {
            return (
              option.children
                .toString()
                .toLowerCase()
                .replace(/\s/g, '')
                .indexOf(input.toLowerCase().replace(/\s/g, '')) >= 0
            );
          } else if (option.label) {
            return (
              option.label
                .toString()
                .toLowerCase()
                .replace(/\s/g, '')
                .indexOf(input.toLowerCase().replace(/\s/g, '')) >= 0
            );
          }
        }}
        defaultValue={defaultValue}
        value={value}
        className={cn(className, 'cx-select', { 'cx-select-disabled': disabled })}
        size="medium"
        labelInValue={false}
        onChange={onChange}
        placeholder={translatedPlaceholder}
        style={style || { minWidth: '200px' }}
        allowClear={allowClear}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={innerRef}
        showArrow={showArrow}
        onInputKeyDown={handleKeyPress}
        onDropdownVisibleChange={onDropdownVisibleChange}
        dropdownRender={dropdownRender}
        open={open}
        tabIndex={tabIndex}
        tagRender={tagRender}
        // dropdownRender={menu => <Dropdown>{menu}</Dropdown>}
        // dropdownStyle={{ backgroundColor: 'var(--select-dropdown-background-color)' }}
      >
        {optionsGroupedByCategory
          ? optionsGroupedByCategory.map(category => (
              <OptGroup label={category.label} key={category.label}>
                {category.options.map(generateOptionList)}
              </OptGroup>
            ))
          : preparedList.map(generateOptionList)}
      </AntSelect>
      {error && touched && <div className="validation-error">{error}</div>}
    </>
  );
};
Select.Option = AntSelect.Option;

Select.propTypes = {
  id: propTypes.string,
  'data-testid': propTypes.string,
  name: propTypes.string,
  mode: propTypes.string,
  valuePath: propTypes.string,
  displayPath: propTypes.oneOfType([propTypes.string, propTypes.array]),
  options: propTypes.array,
  disabled: propTypes.bool,
  className: propTypes.string,
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.array]),
  onChange: propTypes.func,
  placeholder: propTypes.string,
  showArrow: propTypes.bool,
  style: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.array]),
  allowClear: propTypes.bool,
  error: propTypes.string,
  touched: propTypes.bool,
  onFocus: propTypes.func,
  onInputKeyDown: propTypes.func,
  innerRef: propTypes.object,
  onBlur: propTypes.func,
  loading: propTypes.bool,
  loadingPlaceholder: propTypes.string,
};

Select.defaultProps = {
  valuePath: 'id',
  displayPath: 'description',
  options: [],
  loadingPlaceholder: 'selectLoadingPlaceholder',
};

export default styled(Select)`
  width: 100%;

  .option-icon {
    margin-right: 10px;
  }
`;
