import { message } from 'antd';
import { omit, cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { api } from '../api';
import API from '../components/order-map/api';
import { API as orderRequestAPI } from '../../dispatch-order-request/api';
import { transformToPostData } from '../transformToPostData';
import { printPDFDocument } from '../util/printPDFDocument';
import { orderRequestStatuses } from '../../dispatch-order-request/utils/orderRequestStatuses';

const prepareOrderForCopy = rawOrder => ({
  ...omit(rawOrder, [
    'crn',
    'id',
    'requestedBy',
    'tickets',
    'ticketCount',
    'totalDeliveredQty',
    'totalOrderedQty',
    'notes',
    'deliverySchedule.startDateTime',
    'deliverySchedule.tickets',
    'supplierStatus',
    'priceSummary',
  ]),
  linkedOrders: rawOrder.linkedOrders.map(lo => ({
    ...omit(
      lo,
      'crn',
      'id',
      'requestedBy',
      'tickets',
      'ticketCount',
      'totalDeliveredQty',
      'totalOrderedQty',
      'notes',
      'deliverySchedule.startDateTime',
      'deliverySchedule.tickets',
      'supplierStatus',
      'priceSummary',
      'orderRef'
    ),
    lineItems: lo?.lineItems?.map?.(li => omit(li, 'priceSummary')) || [],
  })),
  orderCopiedFromOrder: rawOrder.id,
});

const transformToFormData = ({ rawOrder, copy, orderType }) => {
  const order = copy ? prepareOrderForCopy(rawOrder) : rawOrder;
  const tickets = order?.tickets || order?.deliverySchedule?.tickets || [];

  const linkedOrders = order?.linkedOrders?.map(lo => {
    let primaryLineItem = lo?.lineItems?.filter?.(li => li.item?.type === orderType);

    primaryLineItem = primaryLineItem?.map?.(li => (li._id ? li : { ...li, _id: uuid() }));

    primaryLineItem = primaryLineItem?.[0];

    const otherLineItems = lo?.lineItems
      ?.filter?.(li => li?.item?.productRef !== primaryLineItem?.item?.productRef)
      ?.map?.(li => (li._id ? li : { ...li, _id: uuid() }));

    const loTickets = lo?.deliverySchedule?.tickets || [];

    return {
      id: lo?.id,
      crn: lo?.crn,
      orderRef: lo?.orderRef,
      primaryLineItem: cloneDeep(primaryLineItem),
      otherLineItems: cloneDeep(otherLineItems),
      supplierStatusRef: lo?.supplierStatus?.supplierStatusRef || order?.supplierStatus?.supplierStatusRef,
      locationRef: lo?.location?.locationRef || order?.location?.locationRef,
      dispatchLocationRef: lo?.location?.dispatchLocationRef || order?.location?.dispatchLocationRef,
      startDateTime: lo?.deliverySchedule?.startDateTime || order?.deliverySchedule?.startDateTime,
      priceSummary: lo?.priceSummary || order?.priceSummary,
      taxRate: lo?.taxRate || order?.taxRate,
      supplierParty: lo?.supplierParty || order?.supplierParty,
      tickets: loTickets,
      minimumLoadChargeLoadsData: lo?.minimumLoadChargeLoadsData,
      // originatorSystemType: lo?.originatorSystemType || order?.originatorSystemType,
      // lineItems: lo?.lineItems || [],
      // origin: lo?.origin || order?.origin,
    };
  });

  return {
    allowEdit: order?.allowEdit,
    address: { ...order?.destination?.address },
    locationRef: order?.location?.locationRef,
    billingLocationRef: order?.billingLocation?.locationRef,
    crn: order?.crn,
    customerRef: order?.customerParty?.entityRef,
    ...(order?.overriddenCustomer && { overriddenCustomer: order?.customerParty }),
    deliveryLocation: order?.directions,
    description: order?.description || '',
    dispatchBackgroundColor: order?.tracking?.backgroundColor,
    directions: order?.directions || '',
    driverInstructions: order?.driverInstructions || '',
    billingNote: order?.billingNote || '',
    notes: order?.notes,
    attachments: order?.attachments,
    orderId: order?.id,
    excludeZoneCharges: order?.excludeZoneCharges,
    distance: order?.distance,
    linkedOrders,

    // linkedOrderSchedules: order?.linkedOrders?.map(lo => {
    //   return {
    //     schedule: lo?.deliverySchedule?.schedule,
    //     orderRef: lo?.orderRef,
    //   };
    // }),
    // linkedOrderLineItems,
    orderCopiedFromOrder: order?.orderCopiedFromOrder,
    paymentMethodRef: order?.paymentMethod?.paymentMethodRef,
    phoneNumber: order?.contacts?.[0]?.phoneNumber,
    contact: order?.contacts?.[1],
    priceSummary: order?.priceSummary,
    projectRef: order?.project?.projectRef,
    projectReference: order?.project?.reference,
    projectNote: order?.note,
    purchaseOrder: order?.purchaseOrder,
    requestedBy: order?.requestedBy,
    salesPersonRef: order?.salesPerson?.profileRef,
    shippingMethodRef: order?.shippingMethod?.shippingMethodRef,
    startDateTime: order?.deliverySchedule?.startDateTime,
    supplier: {
      crn: order?.supplierParty?.entityRef,
      name: order?.supplierParty?.name,
      costBookRef: order?.supplierParty?.costBookRef,
      costBookOverride: order?.supplierParty?.costBookOverride,
      manualOverride: order?.supplierParty?.manualOverride,
      resolvedCostBookRef: order?.supplierParty?.resolvedCostBookRef,
      timeZone: order?.supplierParty?.timeZone,
    },
    supplierTimeZone: order?.supplierParty?.timeZone,
    supplierStatusRef: order?.supplierStatus?.supplierStatusRef,
    taxRateRef: order?.taxRate?.taxRateRef,
    tickets,
    ticketsMeta: {
      count: order?.deliverySchedule?.tickets?.length || 0,
      // totalDeliveredQuantity,
      // totalOrderedQuantity,
    },
    travelTime: order?.destination?.scheduleTimes?.travel,
    unloadTime: order?.destination?.scheduleTimes?.unload,
    vehicleTypeRef: order?.vehicleType?.vehicleTypeRef,
    workTypeRef: order?.workType?.workTypeRef,
    taxExemptReasonRef: order?.taxExemptReasonRef,
    dispatchLocationRef: order?.location?.dispatchLocationRef,
  };
};

const updateRoute = ({ waypoints, entityRef, orderRef }) => {
  const body = {
    waypointArray: waypoints?.map?.(waypoint => [waypoint.lng, waypoint.lat]),
  };
  return API.updateRoute({ entityRef, orderRef, body });
};

export const useOrder = (options = {}) => {
  const { showToast, orderType } = options;
  const { entityRef } = useParams();
  const [order, setOrder] = useState(null);
  const [rawOrder, setRawOrder] = React.useState(null);
  const [orderBusy, setOrderBusy] = useState(false);
  const [carrierBusy, setCarrierBusy] = useState(false);
  const [vehicleBusy, setVehicleBusy] = useState(false);
  const [ticketBusy, setTicketBusy] = useState(false);
  const [geoLocations, setGeoLocations] = useState(false);
  const [geoLocationBusy, setGeoLocationBusy] = useState(false);
  const [orderRequestStatus, setOrderRequestStatus] = useState(null);

  const getOrder = useCallback(
    (orderCrn, options = { copy: false }) => {
      setOrder(null);
      setOrderBusy(true);

      const getOrder = options?.expand?.includes?.('tickets') ? api.getOrderTickets : api.getOrder;

      let expand = undefined;
      if (options?.expand?.includes?.('order-group')) {
        expand = 'order-group';
      }

      return getOrder(entityRef, orderCrn, { expand })
        .then(response => {
          setOrder(transformToFormData({ rawOrder: response, copy: options?.copy, orderType }));
          setRawOrder(options?.copy ? prepareOrderForCopy(response) : response);
          return response;
        })
        .finally(() => {
          setOrderBusy(false);
        });
    },
    [entityRef, orderType]
  );

  const getOrderRequest = useCallback(
    orderRequestRef => {
      setOrder(null);
      setOrderBusy(true);

      return orderRequestAPI
        .getOrderRequest(entityRef, orderRequestRef)
        .then(orderRequest => {
          setOrder(transformToFormData({ rawOrder: orderRequest, copy: false, orderType }));
          setRawOrder(orderRequest);
          setOrderRequestStatus(orderRequest.status);
          return orderRequest;
        })
        .finally(() => {
          setOrderBusy(false);
        });
    },
    [entityRef, orderType]
  );

  const applyRawOrder = React.useCallback(
    o => {
      setOrder(transformToFormData({ rawOrder: o, orderType }));
      setRawOrder(o);
    },
    [orderType]
  );

  const applyRawLineOrder = React.useCallback(
    lo => {
      const orderRef = lo?.crn || lo?.orderRef;

      const copiedOrder = {
        ...rawOrder,
        linkedOrders: rawOrder?.linkedOrders?.map?.(o => {
          if ((o?.crn || o?.orderRef) === orderRef) {
            return { ...o, ...lo };
          }

          return o;
        }),
      };
      applyRawOrder(copiedOrder);
    },
    [rawOrder, applyRawOrder]
  );

  const getOrderHistory = useCallback(
    orderCrn => {
      setOrder(null);
      setOrderBusy(true);

      return api
        .getOrderHistory(entityRef, orderCrn)
        .then(response => {
          setOrder(response);

          return response;
        })
        .finally(() => {
          setOrderBusy(false);
        });
    },
    [entityRef]
  );

  const updateOrder = useCallback(
    formData => {
      let hide;
      const postData = transformToPostData(entityRef, formData);

      setOrderBusy(true);
      if (showToast) {
        hide = message.loading({ content: 'Updating order...', style: { marginTop: '20px' }, duration: 0 });
      }

      return api
        .updateOrder(entityRef, formData?.supplier?.crn, postData)
        .then(response => {
          if (showToast) {
            hide?.();
            setTimeout(() => {
              message.success({
                content: 'Order has been successfully updated.',
                style: { marginTop: '20px' },
                duration: 1.5,
              });
            }, 250);
          }
          setOrder(transformToFormData({ rawOrder: response, orderType }));
          setRawOrder(response);
          return response;
        })
        .finally(() => {
          hide?.();
          setOrderBusy(false);
        });
    },
    [entityRef, orderType, showToast]
  );
  const updateOrderAddress = useCallback(
    (entityRef, orderRef, address) => {
      let hide;

      setOrderBusy(true);
      if (showToast) {
        hide = message.loading({ content: 'Updating order...', style: { marginTop: '20px' }, duration: 0 });
      }

      return api
        .updateOrderAddress(entityRef, orderRef, address)
        .then(response => {
          if (showToast) {
            hide?.();
            setTimeout(() => {
              message.success({
                content: 'Order has been successfully updated.',
                style: { marginTop: '20px' },
                duration: 1.5,
              });
            }, 250);
          }
          setOrder(transformToFormData({ rawOrder: response, orderType }));
          setRawOrder(response);
          return response;
        })
        .finally(() => {
          hide?.();
          setOrderBusy(false);
        });
    },
    [orderType, showToast]
  );

  const handleCreateOrder = useCallback(
    async formData => {
      const postData = transformToPostData(entityRef, formData);

      const order = await api.createOrder(entityRef, postData);

      if (formData?._waypoints) {
        await updateRoute({ waypoints: formData._waypoints, entityRef, orderRef: order.crn });
      }

      return order;
    },
    [entityRef]
  );

  const createMultipleOrdersByDate = useCallback(
    async formDataList => {
      let hide;
      const responses = [];

      try {
        setOrder(null);
        setOrderBusy(true);

        if (showToast) {
          hide = message.loading({
            content: `Creating ${formDataList?.length} Orders...`,
            style: { marginTop: '20px' },
            duration: 0,
          });
        }

        const { orderIds } = await api.reserveOrderIds(entityRef, { ordersQuantity: formDataList?.length });

        if (!orderIds?.length) {
          throw new Error('Failed to reserve order ids');
        }

        const promises = [];
        for (let i = 0; i < formDataList?.length; i++) {
          const formData = formDataList[i];

          const id = orderIds?.shift();
          promises.push(
            handleCreateOrder({ ...formData, orderId: id, setOrderId: false })
              .then(order => {
                responses.push({
                  id: order?.id,
                  crn: order?.crn,
                  startDateTime: order?.deliverySchedule?.startDateTime,
                  error: false,
                });
              })
              .catch(err => {
                responses.push({
                  id: err?.id,
                  crn: err?.crn,
                  startDateTime: err?.deliverySchedule?.startDateTime || formData?.startDateTime,
                  error: true,
                  message: err?.message,
                });
              })
          );
        }

        await Promise.all(promises);

        hide?.();
        setOrderBusy(false);

        return responses;
      } catch (error) {
        hide?.();
        setOrderBusy(false);
        throw error;
      }
    },
    [showToast, handleCreateOrder, entityRef]
  );

  const createOrder = useCallback(
    async formData => {
      let hide;
      try {
        setOrder(null);
        setOrderBusy(true);

        if (showToast) {
          hide = message.loading({ content: 'Creating order...', style: { marginTop: '20px' }, duration: 0 });
        }

        const order = await handleCreateOrder(formData);

        setOrder(transformToFormData({ rawOrder: order, orderType }));

        hide?.();
        setOrderBusy(false);

        if (showToast) {
          message.success({
            content: 'Order has been successfully created.',
            style: { marginTop: '20px' },
            duration: 1.5,
          });
        }

        return order;
      } catch (error) {
        hide?.();
        setOrderBusy(false);
        throw error;
      }
    },
    [orderType, showToast, handleCreateOrder]
  );

  const approveOrderRequest = useCallback(
    async (formData, orderRequestRef) => {
      let hide;
      try {
        const postData = transformToPostData(entityRef, formData);

        setOrder(null);
        setOrderBusy(true);
        if (showToast) {
          hide = message.loading({ content: 'Creating order...', style: { marginTop: '20px' }, duration: 0 });
        }

        const response = await orderRequestAPI.createOrderFromOrderRequest(entityRef, orderRequestRef, postData);

        const { order, orderRequest } = response || {};

        setOrder(transformToFormData({ rawOrder: order, orderType }));
        setOrderRequestStatus(orderRequest.status);

        if (formData?._waypoints) {
          await updateRoute({ waypoints: formData._waypoints, entityRef, orderRef: order.crn });
        }

        hide?.();
        setOrderBusy(false);

        if (showToast) {
          message.success({
            content: 'Order has been successfully created.',
            style: { marginTop: '20px' },
            duration: 1.5,
          });
        }

        return order;
      } catch (error) {
        hide?.();
        setOrderBusy(false);
        throw error;
      }
    },
    [entityRef, orderType, showToast]
  );

  const rejectOrderRequest = useCallback(
    orderRequestRef => {
      orderRequestAPI
        .updateOrderRequestStatus(entityRef, orderRequestRef, { status: orderRequestStatuses.rejected })
        .then(orderRequest => {
          setOrderRequestStatus(orderRequest.status);
          message.success({
            content: 'Order Request has been successfully rejected.',
            style: { marginTop: '20px' },
            duration: 2,
          });
        });
    },
    [entityRef]
  );

  const createOrderTicket = useCallback(
    (orderRef, params) => {
      setOrder(null);
      setTicketBusy(true);

      return api
        .createOrderTicket(entityRef, orderRef, params)
        .then(response => {
          if (response.Link) {
            printPDFDocument(response.Link);
          }
          applyRawOrder(response);
          return response;
        })
        .finally(() => {
          setTicketBusy(false);
        });
    },
    [applyRawOrder, entityRef]
  );

  const listOrderCarriers = useCallback(
    (orderRef, params) => {
      setCarrierBusy(true);

      return api.listOrderCarriers(entityRef, orderRef, params).finally(() => {
        setCarrierBusy(false);
      });
    },
    [entityRef]
  );

  const listOrderVehicles = useCallback(
    (orderRef, carrierRef) => {
      setVehicleBusy(true);

      return api.listOrderVehicles(entityRef, orderRef, carrierRef).finally(() => {
        setVehicleBusy(false);
      });
    },
    [entityRef]
  );

  const listOrderTicketGeoLocations = useCallback(
    (orderRef, supplierRef) => {
      setGeoLocationBusy(true);

      return api
        .listOrderTicketGeoLocations(entityRef, orderRef, { supplierRef })
        .then(setGeoLocations)
        .finally(() => {
          setGeoLocationBusy(false);
        });
    },
    [entityRef]
  );

  const isTicketed = React.useMemo(() => order?.tickets?.filter?.(t => !t.ticket.isVoided)?.length, [order]);

  return {
    getOrder,
    setOrder,
    applyRawOrder,
    applyRawLineOrder,
    getOrderHistory,
    createOrderTicket,
    order,
    rawOrder,
    orderBusy,
    updateOrder,
    updateOrderAddress,
    createOrder,
    createMultipleOrdersByDate,
    listOrderCarriers,
    carrierBusy,
    listOrderVehicles,
    vehicleBusy,
    ticketBusy,
    listOrderTicketGeoLocations,
    geoLocationBusy,
    geoLocations,
    isTicketed,
    getOrderRequest,
    approveOrderRequest,
    rejectOrderRequest,
    orderRequestStatus,
  };
};
