import { Drawer } from 'antd';
import cn from 'classnames';
import React, { useRef } from 'react';
import styled from 'styled-components';
import Button from '../../../../../../../../components/button/Button';
import { PRIMARY_PRODUCT_TYPES } from '../../../../../../constants';
import { useDrawer } from '../../../useDrawer';
import { useOrderContext } from '../../../../../useOrderContext';
import EditLineItem from './edit-line-item/EditLineItem';
import LineItem from './LineItem';
import style from './style';

const excludePrimaryProducts = product => !PRIMARY_PRODUCT_TYPES.includes(product?.type);
const restrictToOrderType = orderType => product => product?.type === orderType;

const ProductEntrySmall = ({
  className,
  lineItem,
  otherLineItems,
  handleUpdateConcreteLineItem,
  handleUpdateOtherLineItems,
  handleDeleteRow,
  readOnly,
  tickets,
}) => {
  const {
    masterData: { masterData },
    orderType,
  } = useOrderContext();

  const addProductButtonRef = useRef();

  const { drawerOpen, openDrawer, closeDrawer, drawerWidth } = useDrawer(600);

  const [activeLineItem, setActiveLineItem] = React.useState(null);

  const handleItemClick = React.useCallback(
    (lineItem, isPrimary) => {
      setActiveLineItem({ ...lineItem, isPrimary });
      openDrawer();
    },
    [openDrawer]
  );

  const handleCloseDrawer = React.useCallback(() => {
    closeDrawer();
    setActiveLineItem(null);
  }, [closeDrawer]);

  const handleAccept = React.useCallback(
    lineItem => {
      if (lineItem?.item?.type === 'CONCRETE') {
        handleUpdateConcreteLineItem(lineItem);
      } else {
        handleUpdateOtherLineItems(lineItem);
      }
      setTimeout(() => addProductButtonRef?.current?.scrollIntoView?.());
    },
    [handleUpdateConcreteLineItem, handleUpdateOtherLineItems]
  );

  const initiateAdd = React.useCallback(() => {
    openDrawer();
  }, [openDrawer]);

  const availableConcreteProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(restrictToOrderType(orderType)),
    [masterData?.availableProducts, orderType]
  );
  const availableOtherProducts = React.useMemo(
    () => masterData?.availableProducts?.filter?.(excludePrimaryProducts),
    [masterData?.availableProducts]
  );

  return (
    <div className={cn('product-entry-small', className)}>
      <div className="section-label">Products</div>
      <div className="line-items">
        <LineItem lineItem={lineItem} onClick={li => handleItemClick(li, true)} />
        {otherLineItems?.map(lineItem => {
          return <LineItem key={lineItem?.item?.productRef} lineItem={lineItem} onClick={handleItemClick} />;
        })}
      </div>
      <Button
        type="primary"
        onClick={initiateAdd}
        disabled={!masterData?.availableProducts?.length}
        innerRef={addProductButtonRef}
        metricId={'core-order-detail-add-product'}
      >
        Add Product
      </Button>
      <StyledDrawer
        title={activeLineItem?._id ? 'Edit Product' : 'Add Product'}
        placement="right"
        width={drawerWidth}
        closable
        onClose={handleCloseDrawer}
        visible={drawerOpen}
        destroyOnClose
      >
        <EditLineItem
          availableProducts={availableConcreteProducts || []}
          availableOtherProducts={availableOtherProducts || []}
          lineItem={activeLineItem}
          onDone={handleCloseDrawer}
          onAccept={handleAccept}
          onDelete={handleDeleteRow}
          readOnly={readOnly}
          tickets={tickets}
        />
      </StyledDrawer>
    </div>
  );
};

export default styled(ProductEntrySmall)`
  ${style}
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;
